import * as React from "react";
import "./techno.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PORT } from "@common";
import { Languages } from "@common";


const hostname =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";

const URL =
  document.location.protocol +
  "//" +
  hostname +
  "/images/technologies/";

export default ({ data, lang, id_bat, name_bat }) => {
  const navigate = useNavigate();

  const pressedCat = (id) => {
    if (id === 3) {
      navigate("/produits");
    } else {
      navigate(
        `/sectors/technologies/${id_bat}/${name_bat}/gammes/${
          data.id_technology
        }/${data.type_technology.replace("/", "_")}`
      );
    }
  };

  return (
    <Card
      sx={{ maxWidth: 345 }}
      onClick={() => pressedCat(data.id_technology)}
      className="product__card"
    >
      <CardMedia component="img" image={URL + data.picture} alt="image" />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{ marginTop: "10px" }}
        >
          {lang === "EN"
            ? !!data.type_technology
              ? data.type_technology
              : data.french_type
            : !!data.french_type
            ? data.french_type
            : data.type_technology}
        </Typography>
      </CardContent>
      <div className="overlay top-overlay">
        <CardActions style={{ width: "36%" }}>
          <Button
            onClick={() =>
              pressedCat(data.id_technology, data?.type_technology)
            }
            size="small"
            color="primary"
            style={{
              fontWeight: "bold",
              color: "#676768 !important",
              textTransform: "capitalize",
              padding: 12,
              width: "100%",
              borderRadius: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#ffffffdb",
            }}
          >
            {Languages.See}
          </Button>
        </CardActions>
      </div>
    </Card>
  );
};
