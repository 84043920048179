import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { SLIDERS } from "@services";

const IP =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";
const URL = document.location.protocol + "//" + IP + "/images/sliders";

const Example = ({ pressed, lang }) => {
  const displayOnce = useRef(true);
  const [sliders, setSliders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      setIsLoading(true);
      SLIDERS.ALL()
        .then((data) => {
          setIsLoading(false);
          setSliders(data.data.data);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <div className="slide-container">
      <Fade onChange={pressed}>
        {sliders.map((ele, index) => (
          <div className="each-slide-effect" key={index} onClick={pressed}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "cover",
                height: "100vh",
                backgroundImage: `url(${URL + "/" + ele.picture})`,
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default Example;
