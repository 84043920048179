import { backend } from "../axios";

export const INFORMATIONCENTER = {
  ALL: async () => {
    return await backend.get("/information/center");
  },
  FETCHONE: async (id) => {
    return await backend.get(`/information/center/${id}`);
  },
};
