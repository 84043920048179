import { backend } from "../axios";

export const PRODUCTS = {
  FINDPRODUCTSBYCONFIG: async (id) => {
    return await backend.get(`/products/find_by_configuration/${id}`);
  },
  DOWNOALD: async (fileNom) => {
       return await backend.get(`/products/download/${fileNom}`,  { responseType: "blob" });
  }
};
