import "./index.css";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Button } from "@components";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import { PORT, Languages } from "@common";

const hostname =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";
const URL =
  document.location.protocol + "//" + hostname + "/images/products/images/";

export default ({ data, pressed, isFilter }) => {
  return (
    <Card
      sx={{
        width: "20%",
        cursor: "pointer",
        background: isFilter && "transparent",
      }}
      className="product__card"
    >
      <CardMedia
        sx={{ height: 240 }}
        image={URL + data?.picture}
        title={data?.ccaen + " " + data?.ccasae}
        className="product_image"
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className={isFilter ? "typoGraphywhite" : "typoGraphy"}
        >
          {data?.ccaen + " " + data?.ccasae}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <div className="desc__pro">
            <div className={isFilter ? "desc__item__black" : "desc__item"}>
              <span>
                Ident Nr: <span>{data?.identifier_number}</span>
              </span>
              <span>
                Capacity (Ah): <span>{data?.ability}</span>
              </span>
              <span>
                Dimensions (mm): <span>{data?.dimension}</span>
              </span>
              <span>
                Item code: <span>{data?.item_code}</span>
              </span>
              <span>
                Short code: <span>{data?.short_code}</span>
              </span>
              <span></span>
              <span>
                Item type: <span>{data?.box_type}</span>
              </span>
            </div>
            {/* <Divider
              orientation="vertical"
              flexItem
              style={{ marginRight: "16px" }}
            />
            <div className={isFilter ? "desc__item__black" : "desc__item"}>
            
            </div> */}
          </div>
        </Typography>
      </CardContent>
      <CardActions
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
          width: "100%",
        }}
      >
        {!!data?.technical_sheet_english && (
          <Button
            pressed={() => pressed(data.technical_sheet_english)}
            label={Languages.TechnicalDataSheet}
            style={{
              color: "#fff8f8",
              textTransform: "capitalize",
              letterSpacing: "3px",
              fontWeight: "bold",
              background: "#1f3c77",
              fontSize: "11px",
              margin: "0px 4px",
              width: "calc(100% - 26px)",
            }}
          />
        )}
        {!!data?.technical_sheet_french && (
          <Button
            pressed={() => pressed(data.technical_sheet_french)}
            label={Languages.TechnicalDataSheetFR}
            style={{
              color: "#fff8f8",
              textTransform: "capitalize",
              letterSpacing: "3px",
              fontWeight: "bold",
              background: "#1f3c77",
              fontSize: "11px",
              margin: "8px 0px",
              width: "calc(100% - 26px)",
            }}
          />
        )}
      </CardActions>
    </Card>
  );
};
