import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./index.css";

const container = {
  hidden: { opacity: 1, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "fade",
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

export default ({ children, height, width, corporateId }) => {
  const [animation, setAnimation] = useState(
    <motion.div
      className="container"
      variants={container}
      initial="hidden"
      animate="visible"
      style={{
        height: !!height ? height : "100%",
        width: !!width ? width : "100%",
      }}
    >
      {children}
    </motion.div>
  );

  useEffect(() => {
    setAnimation(
      <motion.div
        className="container"
        variants={container}
        initial="hidden"
        animate="visible"
        style={{
          height: !!height ? height : "100%",
          width: !!width ? width : "100%",
        }}
        key={corporateId} // add key to force a re-render
      >
        {children}
      </motion.div>
    );
  }, [corporateId, children, height, width]); // add all dependencies

  return <AnimatePresence>{animation}</AnimatePresence>;
};
