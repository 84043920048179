import "./index.css";
import React from "react";
// import banner from "@images/banner.png";
import { Button } from "@components";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import ConstructionIcon from "@mui/icons-material/Construction";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const Data = [
  {
    id: 1,
    name: "Automobiles and Light Commercial Vehicles",
    IconName: DirectionsCarIcon,
  },
  { id: 2, name: "Heavy Duty", IconName: LocalShippingIcon },
  { id: 3, name: "Special Series", IconName: DirectionsBoatFilledIcon },
  { id: 4, name: "Industrial Batteries", IconName: ConstructionIcon },
  { id: 5, name: "Service Equipments", IconName: AddRoadIcon },
  { id: 6, name: "All Products", IconName: ListAltIcon },
];

export default ({}) => {
  const navigate = useNavigate();
  return (
    <div className="box__banner">
      <div className="banner____box">
        {!!Data &&
          Data.map(({ id, name, IconName }, index) => (
            <div
              key={id}
              className="banner__item"
              onClick={() => navigate("/produits")}
            >
              <IconName style={{ color: "#5a6ea4", marginRight: "9px" }} />
              <p className="shown_box_name">{name}</p>
            </div>
          ))}
      </div>

      <div
        // style={{ backgroundImage: `url(${banner})` }}
        className="banner_image"
      >
        <p className="banner__desc">Find the Right Battery for Your Vehicle </p>
        <Button
          label={"Explore"}
          useIcon
          IconName={ArrowForwardIosIcon}
          style={{
            color: "white",
            fontWeight: "bold",
            marginTop: "5px",
            marginLeft: "2px",
          }}
        />
      </div>
    </div>
  );
};
