import "../index.css";
// import React from "react";

// export default ({ data }) => {
//   return (
//     <div>
//       <ul className="cat__list">
//         <li className="cat__item">
//           <a className="cat__link">{data?.name}</a>
//         </li>
//       </ul>
//       {data?.sub.length > 0 && (
//         <ul className="sul__list">
//           {!!data?.sub &&
//             data?.sub.map((ele, index) => (
//               <li className="sub__item" key={index}>
//                 <a className="link__item">{ele?.sub_name}</a>
//               </li>
//             ))}
//         </ul>
//       )}
//     </div>
//   );
// };

import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default ({
  data,
  handleChange,
  expanded,
  technologies,
  expanded2,
  handleChange2,
  gammes,
  expanded3,
  handleChange3,
  configurations,
  handleClickConf,
  lang,
}) => {
  return (
    <div>
      <Accordion
        expanded={expanded === `panel3-${data.id_sector}`}
        onChange={handleChange(`panel3-${data.id_sector}`, data.id_sector)}
        style={{ border: "1px solid rgba(62, 80, 128, 0.12)" }}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            {lang === "EN"
              ? !!data?.type_sector
                ? data?.type_sector
                : data.french_type
              : !!data.french_type
              ? data.french_type
              : data.type_sector}
          </Typography>
        </AccordionSummary>

        {technologies.length > 0 &&
          technologies?.map((ele, index) => (
            <AccordionDetails
              key={index}
              style={{ cursor: "pointer" }}
              className="sub__cat__filter"
            >
              {/* <Typography>{ele.type_technology}</Typography> */}
              <Accordion
                expanded={expanded2 === `panel4-${ele.id_technology}`}
                onChange={handleChange2(
                  `panel4-${ele.id_technology}`,
                  ele.id_technology
                )}
                style={{ border: "1px solid rgba(62, 80, 128, 0.12)" }}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    {lang === "EN"
                      ? !!ele?.type_technology
                        ? ele?.type_technology
                        : ele?.french_type
                      : !!ele.french_type
                      ? ele.french_type
                      : ele.type_technology}
                  </Typography>
                </AccordionSummary>
                {gammes.length > 0 &&
                  gammes.map((gamme, index) => (
                    <AccordionDetails
                      key={gamme.id_selection}
                      style={{ cursor: "pointer" }}
                      className="sub__cat__filter"
                    >
                      {/* {gamme.name_selection} */}
                      <Accordion
                        expanded={expanded3 === `panel5-${gamme.id_selection}`}
                        onChange={handleChange3(
                          `panel5-${gamme.id_selection}`,
                          gamme.id_selection
                        )}
                        style={{ border: "1px solid rgba(62, 80, 128, 0.12)" }}
                      >
                        <AccordionSummary
                          aria-controls="panel3d-content"
                          id="panel3d-header"
                        >
                          <Typography>
                            {lang === "EN"
                              ? !!gamme?.name_selection
                                ? gamme?.name_selection
                                : gamme.french_name
                              : !!gamme.french_name
                              ? gamme.french_name
                              : gamme.name_selection}
                          </Typography>
                        </AccordionSummary>
                        {configurations.length > 0 &&
                          configurations.map((conf, index) => (
                            <AccordionDetails
                              key={conf.id_configuration}
                              style={{ cursor: "pointer" }}
                              className="sub__cat__filter"
                            >
                              <Typography
                                style={{ marginLeft: 18 }}
                                onClick={() =>
                                  handleClickConf(
                                    conf.id_configuration,
                                    conf.type_configuration
                                  )
                                }
                              >
                                {lang === "EN"
                                  ? !!conf?.type_configuration
                                    ? conf?.type_configuration
                                    : conf.french_type
                                  : !!conf.french_type
                                  ? conf.french_type
                                  : conf.type_configuration}
                              </Typography>
                            </AccordionDetails>
                          ))}
                      </Accordion>
                    </AccordionDetails>
                  ))}
              </Accordion>
            </AccordionDetails>
          ))}
      </Accordion>
    </div>
  );
};
