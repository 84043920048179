import { useIsDarkMode } from "./hooks";

// This hook returns the colors object based on the user's prefarred colorScheme (Light / Dark)

const useColors = () => {
  let isDarkMode = useIsDarkMode();
  // the Color palette for the whole AP
  return {
    background: isDarkMode ? "#000" : "#fff",
    backgroundDark: isDarkMode ? "#212121" : "rgb(220, 220, 220)",
    white: isDarkMode ? "#424242" : "#FFFFFF",
    blackText: isDarkMode ? "#ffffff" : "#464646",
    whiteText: isDarkMode ? "#424242" : "#FFF",
    grayBack: isDarkMode ? "#424242" : "#eee",
    mediumGray: "#bdbdbd",
    primary: "#e38e16",
    // primary: "#e1b761",
    primaryDark: "#e29701",
    primaryLight: "#ffcc80",
    secondary: isDarkMode ? "#262626" : "rgb(200, 205, 200)",
    grayText: "#212121",
    lightGrayText: "#bdbdbd",
    success: "#009688",
    dark: "#616161",
    error: isDarkMode ? "#ef5350" : "#d32f2f",
    backgroundFade: isDarkMode ? "#42424247" : "#eeeeee",
    shadowColor: isDarkMode ? "#151515ba" : "#15151526",
    greenColor: "#1ca600",
    bgBox: isDarkMode ? "#2b2b2bc2" : "#ffffffc2",
    headerSkin: isDarkMode
      ? "rgba(37, 37, 37, 0.84)"
      : "rgba(189, 180, 180, 0.23)",
    tableBg: isDarkMode ? "rgba(0, 0, 0, 0.97)" : "rgba(255, 255, 255, 0.55)",
    disbledBack: "rgb(181, 181, 181)",
    disbledColor: 'rgb(130, 127, 127)'
  };
};

export default useColors;
