import React, { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Logo2 from "@images/logo.jpeg";
import { Button } from "@components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import banner from "@images/basic_banner.png";
import "./index.css";
import gorilla from "@images/gorilla.png";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";
import { SOCIALS } from "@services";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Languages } from "@common";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [socials, setSocials] = useState([]);
  const displayOnce = useRef(true);

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
    }
    fetchSocials();
  }, []);

  const fetchSocials = () => {
    setIsLoading(true);
    SOCIALS.ALL()
      .then((social) => {
        setSocials(social.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clickEvent = (element) => {
    if (element === "corporate") {
      navigate("/corporates");
    } else if (element === "contact") {
      navigate("/contact");
    } else if(element === "sectors") {
      navigate("/sectors");
    } else {
      navigate("/information_center");
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", background: "#2c2e83" }}>
          <Toolbar className="tab_header">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <img src={Logo2} alt="Logo" style={{ width: 120 }} />
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button onClick={() => clickEvent("corporate")}>
            <ArrowForwardIosSharpIcon style={{ color: "grey", fontSize: 16 }} />
            <ListItemText
              primary={Languages.Corporate}
              style={{ marginLeft: 8 }}
            />
          </ListItem>
          <ListItem button onClick={() => clickEvent("sectors")}>
            <ArrowForwardIosSharpIcon style={{ color: "grey", fontSize: 16 }} />
            <ListItemText
              primary={Languages.FindBattery}
              style={{ marginLeft: 8 }}
            />
          </ListItem>
          <ListItem button onClick={() => clickEvent("infos")}>
            <ArrowForwardIosSharpIcon style={{ color: "grey", fontSize: 16 }} />
            <ListItemText
              primary={Languages.InformationCenter}
              style={{ marginLeft: 8 }}
            />
          </ListItem>
          <ListItem button onClick={() => clickEvent("contact")}>
            <ArrowForwardIosSharpIcon style={{ color: "grey", fontSize: 16 }} />
            <ListItemText
              primary={Languages.Contact}
              style={{ marginLeft: 8 }}
            />
          </ListItem>
        </List>
        <div className="res_social_media">
          {socials.map((ele, index) => (
            <div key={index} onClick={() => window.open(ele.link)}>
              {ele.tag === "Facebook_tag" && (
                <FacebookIcon
                  className="social_item"
                  style={{ fontSize: 20 }}
                />
              )}
              {ele.tag === "LinkedIn_tag" && (
                <LinkedInIcon style={{ fontSize: 20 }} />
              )}
              {ele.tag === "Instagram_tag" && (
                <InstagramIcon
                  className="social_item"
                  style={{ fontSize: 20 }}
                />
              )}
              {ele.tag === "Youtube_tag" && (
                <YouTubeIcon className="social_item" style={{ fontSize: 20 }} />
              )}
              {ele.tag === "Whatsapp_tag" && (
                <WhatsAppIcon
                  className="social_item"
                  style={{ fontSize: 20 }}
                />
              )}
            </div>
          ))}
        </div>
        <div
          style={{ backgroundImage: `url(${banner})` }}
          className="banner_image__responsive"
        >
          <div className="content__responsive">
            <div className="text__box__responsive">
              <p className="banner__desc_responseive">
                Find the Right Battery for Your Vehicle{" "}
              </p>
              <Button
                label={"Explore"}
                useIcon
                IconName={ArrowForwardIosIcon}
                style={{
                  color: "white",
                  marginTop: "5px",
                  marginLeft: "2px",
                }}
              />
            </div>
            <img src={gorilla} alt="gorilla" className="gorilla_responsive" />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
