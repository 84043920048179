
import { useEffect, useState } from "react";

// This hook returns the darkmode value based on state and system values
export const useIsDarkMode = () => {
    // Get darkmode value from redux state
    const darkModeInState = null; //useSelector(({account: {isDarkMode}}) => isDarkMode);
    // Get darkmode value from system
    const darkModeInSystem = () =>
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    // Calc value based on state
    const [isDarkTheme, setIsDarkTheme] = useState(darkModeInSystem());
  
    const mqListener = (e) => {
      setIsDarkTheme(e.matches);
    };
  
    useEffect(() => {
      const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
      darkThemeMq.addListener(mqListener);
      return () => darkThemeMq.removeListener(mqListener);
    }, []);
  
    let darkModeValue = darkModeInState === null ? isDarkTheme : darkModeInState;
    // Return calculated darkmode value
    return darkModeValue;
  };