import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vd from "@images/almaVD.mp4";
import { useNavigate } from "react-router-dom";
import { SLIDERS } from "@services";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const IP =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";
const URL = document.location.protocol + "//" + IP + "/images/sliders";

const Slider2 = () => {
  const displayOnce = useRef(true);
  const [sliders, setSliders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentItem, setCurrentItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);

  const handleImageLoad = () => {
    setIsLoaded(true);
    setTimeout(() => {
      setFadeIn(true);
    }, 300);
  };

  const handleImageError = () => {
    setHasError(true);
  };

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      const video = document.getElementById('myVideo');
      video.play();
      setIsLoading(true);
      SLIDERS.ALL()
        .then((data) => {
          setIsLoading(false);
          setSliders(data.data.data);
          setCurrentItem(data.data.data[0]);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  }, []);

  const goToNextSlide = () => {
    if (sliders.length - 1 === currentIndex) {
      setCurrentIndex(-1);
      // setCurrentItem(sliders[0]);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setCurrentItem(sliders[currentIndex + 1]);
    }
    setIsLoaded(false); // Reset isLoaded state when changing the current item
    setHasError(false);
    setTimeout(() => {
      setFadeIn(false);
    }, 300);
  };

  const goToPrevSlide = () => {
    if (currentIndex === -1) {
      return;
    }
    if (currentIndex === 0 || currentIndex === -1) {
      setCurrentIndex(-1);
    } else {
      // console.log("*************************");
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setCurrentItem(sliders[currentIndex - 1]);
    }
    setIsLoaded(false); // Reset isLoaded state when changing the current item
    setHasError(false);
    setTimeout(() => {
      setFadeIn(false);
    }, 300);
  };

  return (
    <div className="slider-container">
      <span className="nav-button prev" onClick={goToPrevSlide}>
        <ArrowBackIosIcon style={{ color: "white" }} />
      </span>
      <div className="slider-content">
        {!isLoaded && (
          <div className="loading_image">
            <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
              <CircularProgress color="inherit" />
            </Stack>
          </div>
        )}
        {currentIndex !== -1 && (
          <div className={`slide ${isLoaded ? "fade-in" : "fade-out"}`}>
            {!!currentItem && (
              <div
                className="backgroundImage_almabat"
                style={{
                  backgroundImage: `url(${URL + "/" + currentItem.picture})`,
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100vh",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {hasError && <p>Error loading image</p>}
                <img
                  src={URL + "/" + currentItem.picture}
                  alt=""
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  style={{ display: "none" }}
                />
              </div>
            )}
          </div>
        )}
        {currentIndex === -1 && (
          <div className={`slide ${isLoaded ? "fade-in" : "fade-out"}`}>
            <video
              id="myVideo"
              autoPlay
              muted
              playsInline
              width="100%"
              height="100%"
              onLoadedData={handleImageLoad}
              onError={handleImageError}
              loop
            >
              <source src={Vd} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
      <span className="nav-button next" onClick={goToNextSlide}>
        <ArrowForwardIosIcon style={{ color: "white" }} />
      </span>
    </div>

    // </Slider>
  );
};

export default Slider2;
