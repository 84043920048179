import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Languages, useColors } from "@common";
import "./index.css";

const hostname =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";

const URL =
  "http://" + hostname + "/images/explain";
export default ({ data }) => {
  const Colors = useColors();
  const languages = useSelector(({ account: { language } }) => language);
  return (
    <div className="explain___data">
      <div className="explain__content">
        {data.map((ele) => (
          <div className="explain__loop_content" key={ele?.id}>
            {ele?.image && <img src={URL + "/" + ele?.image} style={{width: "60%"}}/>}
            <div className="desc___explain">
              <div
                className="explain__description"
                dangerouslySetInnerHTML={{
                  __html: languages === "EN" ? ele?.desc : ele?.descfr,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
