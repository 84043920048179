import React from "react";
import { format } from "date-fns";
import { useColors, Fonts } from "@common";
import { makeStyles } from "@mui/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

export default ({ date, style, avoidTime, useDate, isNotDate }) => {
  const Colors = useColors();
  const classes = useStyle();

  return (
    <p style={style}>

      {isNotDate ? date.trim() : format(date, !useDate ? "dd/MM/yyyy" : "yyyy")}{" "}
    </p>
  );
};

const useStyle = makeStyles({
  itemDate: {
    fontFamily: Fonts().primaryRegular,
  },
});
