import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import {
  TabBar,
  HeaderBoxChown,
  Footer,
  AnimationWrapper,
  Explain,
} from "@components";
import { useNavigate, useParams } from "react-router-dom";
import CategoryItem from "./components/Technology";
import { TECHNOLOGIES, EXPLAIN } from "@services";
import { Languages } from "@common";
import { useSelector } from "react-redux";

export default ({}) => {
  const { id, name } = useParams();
  const languages = useSelector(({ account: { language } }) => language);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [technologies, setTechnologies] = useState([]);
  const [explain, setExplain] = useState([]);
  const displayOnce = useRef(true);

  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchtechnologies();
      fetchExplain();
    }
  }, [languages]);

  const fetchtechnologies = () => {
    setIsLoading(true);
    TECHNOLOGIES.BYSECTORS(id)
      .then((result) => {
        setIsLoading(false);
        if (result.data.data) {
          setTechnologies(result.data.data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(
            error,
            "****** This error is catched in the fetch technologies by sectors list function ***********"
          );
        }
      });
  };

  const fetchExplain = () => {
    setIsLoading(true);
    EXPLAIN.ALL("technologies")
      .then((result) => {
        setIsLoading(false);
        if (result.data.data) {
          setExplain(result.data.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err) {
          console.log(
            err,
            "****** This error is catched in the fetch explain list function ***********"
          );
        }
      });
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <AnimationWrapper>
          <div
            onClick={() => setOpen(true)}
            className="shown__box"
            style={
              !open
                ? { opacity: 0, zIndex: -100, height: 0, top: "-300px" }
                : {
                    opacity: 1,
                    transition: "all .3s ease-in-out",
                    zIndex: 9999,
                    height: "auto",
                    top: "80px",
                  }
            }
          >
            <HeaderBoxChown />
          </div>
        </AnimationWrapper>
      </div>

      <AnimationWrapper>
        <div className="category_container">
          <div className="text__content">
            <h1 className="grande__title">{Languages.OurTechnologies}</h1>
            {/* <p className="cat__desc">{Languages.OurTechnologiesDesc}</p> */}
          </div>
          <Explain data={explain} />
          <div className="items__box">
            {technologies.length > 0 &&
              technologies.map((ele) => (
                <CategoryItem
                  data={ele}
                  key={ele.id_technology}
                  lang={languages}
                  id_bat={id}
                  name_bat={name}
                />
              ))}
          </div>
        </div>
      </AnimationWrapper>
      <Footer />
    </>
  );
};
