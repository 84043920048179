import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.css";
import { SET_LANGUAGE } from "@redux/account/types";
import { Languages } from "@common";
import { useDispatch, useSelector } from "react-redux";

const options = [
  { id: 1, name: "EN" },
  { id: 2, name: "FR" },
];

const ITEM_HEIGHT = 48;

export default ({}) => {
  const dispatch = useDispatch();
  const languages = useSelector(({ account: { language } }) => language);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState(
    !!languages ? languages : "EN"
  );
  Languages.setLanguage(!!languages ? languages : "EN");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleselect = (e) => {
    setSelectedLang(e.target.textContent);
    dispatch({ type: SET_LANGUAGE, payload: e.target.textContent });
    Languages.setLanguage(e.target.textContent);
    handleClose();
  };

  return (
    <div
      style={{
        marginRight: 12,
        width: window.innerWidth < 1199 && "100%",
        textAlign: "end",
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <p className="lang__p">{selectedLang}</p>
        <KeyboardArrowDownIcon style={{ color: "#fff" }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "10ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            selected={option === "EN"}
            onClick={handleselect}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
