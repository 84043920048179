import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./index.css";

export default ({ Label, data }) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{Label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label={Label}
          onChange={handleChange}
        >
          {!!data &&
            data.map((ele, index) => (
              <MenuItem key={ele.id} value={ele.id}>
                {ele.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
