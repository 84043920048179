import "./index.css";
import React, { useState } from "react";

export default ({}) => {
  // const navigate = useNavigate();
  // const [open, setOpen] = useState(false);

  // const openMenu = (param) => {
  //   if (param === "prod") {
  //     setOpen((prv) => !prv);
  //   } else if (param === "cat") {
  //     navigate("/sectors");
  //   } else {
  //     navigate("/");
  //   }
  // };

  return (
    <div>
      hello
      {/* <div style={{ position: "relative", zIndex: 1 }}> */}
        {/* <TabBar pressed={openMenu} useBack /> */}
        {/* <div
          onClick={() => setOpen(true)}
          className="shown__box"
          style={
            !open
              ? { opacity: 0, zIndex: -100, height: 0, top: "-300px" }
              : {
                  opacity: 1,
                  transition: "all .3s ease-in-out",
                  zIndex: 9999,
                  height: "auto",
                  top: "80px",
                }
          }
        >
          <HeaderBoxChown />
        </div> */}
        {/* )} */}
      {/* </div> */}
      {/* <div className="detail__page">
        <div className="section__details">
          <img src={Details} alt="Details" className="image__details" />
          <div>
            <Button
              onClick={() => navigate(-1)}
              label="Back"
              useIcon
              IconName={ArrowBackIosIcon}
              isFirst
              style={{
                width: "120px",
                padding: "8px 3px",
                background: "#6260604a",
                borderRadius: "6px",
                position: "absolute",
                top: "19px",
                left: "19px",
                color: "#ececec",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            />
          </div>
        </div>

        <div className="section__content">
          <div className="section__content__retalive__info">
            <p className="retalive__info__category">
              Automobiles and Light Commercial Vehicles
            </p>
            <h1 className="retalive__info__title">FORMUL A TAURUS</h1>
            <p className="retalive__info__desc">
              A reliable option of vehicle manufacturers, Formul A is designed
              with totally-enclosed special cap system that does not require any
              maintenance to meet standard electricity requirements of
              conventional vehicles.
            </p>
            <h2 className="retalive__info__second__title">
              MAINTENANCE FREE SYSTEM
            </h2>
            <p className="retalive__info__second__desc">
              A reliable option of vehicle manufacturers, Formul A is designed
              with totally-enclosed special cap system that does not require any
              maintenance to meet standard electricity requirements of
              conventional vehicles.
            </p>
            <h2 className="retalive__info__third__title">
              BATTERY PERFORMANCE VALUES (EUROPEAN STANDARDS)
            </h2>
            <div className="retalive__info__battrey__performance__images">
              <img src={w3} />
              <img src={c2} />
              <img src={v2} />
              <img src={e1} />
            </div>
            <h2 className="retalive__info__fourth__title">APPLICATION AREAS</h2>
            <div className="retalive__info__app__area__images">
              <img src={cars} alt="cars" />
            </div>
            <h2 className="retalive__info__fifth__title">PRODUCT ADVANTAGES</h2>
            <div className="retalive__info__app__area__images">
              <div className="retalive__info__app__area__images__desc">
                <div className="___desc__content">
                  <img src={bat1} className="battries__area__image" />
                  <p className="title__area">
                    Maintenance-Free Totally-Enclosed System{" "}
                  </p>
                </div>

                <div className="___desc__content">
                  <img src={bat2} className="battries__area__image" />
                  <p className="title__area">
                    Patented Totally-Enclosed Special Cap System{" "}
                  </p>
                </div>
              </div>
              <div className="retalive__info__app__area__images__desc">
                <div className="___desc__content">
                  <img src={bat3} className="battries__area__image" />
                  <p className="title__area">Ca-Ca Alloy Plates </p>
                </div>
                <div className="___desc__content">
                  <img src={bat4} className="battries__area__image" />
                  <p className="title__area">
                    QR and Barcode Technology Enabling Product Tracking{" "}
                  </p>
                </div>
              </div>
            </div>
            <h2 className="retalive__info__sixsth__title">OUR PRODUCTS</h2>
            <div className="products_number">
              <div className="number__serction__avoid">
                <h1>
                  50 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  55 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  60 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  66 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  72 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  82 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  92 <span>Ah</span>
                </h1>
              </div>
              <div>
                <h1>
                  45 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  75 <span>Ah</span>
                </h1>
              </div>
              <div className="number___section">
                <h1>
                  90 <span>Ah</span>
                </h1>
              </div>
            </div>
            <h2 className="retalive__info__seventh__title">
              TECHNICAL INFORMATION
            </h2>
            <div className="detail__actions">
              <div className="download__section">
                <p className="download__section__p">Product Catalog</p>
                <Button
                  label="DOWNLOAD"
                  useIcon
                  IconName={FileDownloadIcon}
                  style={{
                    background: "#f4cb40",
                    width: "211px",
                    padding: "10px 0px",
                    margin: " 24px 5px",
                    color: "white",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                  iconStyle={{ fontSize: "26px", marginLeft: "8px" }}
                />
              </div>
              <div className="download__section">
                <p className="download__section__p">
                  Technical Information Catalog
                </p>
                <Button
                  label="DOWNLOAD"
                  useIcon
                  IconName={FileDownloadIcon}
                  style={{
                    background: "#f4cb40",
                    width: "211px",
                    padding: "10px 0px",
                    margin: " 24px 5px",
                    color: "white",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                  iconStyle={{ fontSize: "26px", marginLeft: "8px" }}
                />
              </div>
            </div>
            <h2 className="retalive__info__eight__title">PRODUCT PROPERTIES</h2>
            <div className="banner__detail">
              <img src={banner} style={{ width: "100%" }} />
            </div>
            <h2 className="retalive__info__eight__title">PRODUCT PROPERTIES</h2>
            <p className="download__section__p">
              Formul A Taurus Product Movie
            </p>
            <div style={{ marginTop: "18apx" }}>
              <iframe
                width="80%"
                height="415"
                src="https://www.youtube.com/embed/nrbcr9rQQG4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="detail__footer">
        <Footer />
      </div> */}
    </div>
  );
};
