import React, { useState, useEffect, useRef } from "react";
import { TabBar, Footer, AnimationWrapper } from "@components";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { CORPORATES } from "@services";
import ImageTitleText from "./components/ImageTitleText";
import TitleLogoDateDescreption from "./components/TitleLogoDateDescreption";
import ImageTitleLogoChecklist from "./components/ImageTitleLogoChecklist";
import ImageTitleSubtitleListe from "./components/ImageTitleSubtitleListe";
import TitleText from "./components/TitleText";
import { Languages } from "@common";
import { useSelector } from "react-redux";

export default ({}) => {
  const params = useParams();
  const languages = useSelector(({ account: { language } }) => language);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [corporates, setCorporates] = useState([]);
  const displayOnce = useRef(true);
  const [tag, setTag] = useState(null);
  const [content, setContent] = useState([]);
  const [corporateId, setCorporateId] = useState(
    !!params?.id ? params?.id : null
  );
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchCorporates();
    }
    setCorporateId(params?.id);
  }, [languages, params?.id]);

  useEffect(() => {
    
    const findContentCorporate = corporates.find(
      (ele) => ele.id_corporate === corporateId
    );
    if (!!findContentCorporate) {
      setTitle(
        languages === "EN"
          ? !!findContentCorporate?.nom_corporate
            ? findContentCorporate?.nom_corporate
            : findContentCorporate?.french_nom
          : !!findContentCorporate?.french_nom
          ? findContentCorporate?.french_nom
          : findContentCorporate?.nom_corporate
      );
      setTag(findContentCorporate?.Contents[0]?.Style?.tags);

      if (
        findContentCorporate?.Contents[0]?.Style?.tags ===
        "title_logo_date_description"
      ) {
        // Custom sorting function to handle different date formats
        const sortedContents = findContentCorporate.Contents.sort((a, b) => {
          if(!a?.date) {
            setContent("");
            return
          }
          const dateA = a?.date.split(" - ")[0]; // Assuming 'date' format is "YYYY" or "YYYY - YYYY"
          const dateB = b?.date.split(" - ")[0]; // Get the first year for comparison

          return dateA.localeCompare(dateB); // Compare years
        });
        setContent(sortedContents);
      } else {
        setContent(findContentCorporate.Contents);
      }
    } else {
      setContent([]);
    }
  }, [corporateId, corporates, languages, params.id]);

  const navigate = useNavigate();

  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  const fetchCorporates = () => {
    
    setIsLoading(true);
    CORPORATES.ALL()
      .then((result) => {
        setIsLoading(false);
        setCorporates(result.data.data);
        if (result.data.data.length > 0) {
          let theID = null;
          if (!params?.id) {
            theID = result.data.data[0].id_corporate;
          } else {
            theID = params?.id;
          }
          const findContentCorporate = result.data.data.find(
            (ele) => ele.id_corporate === theID
          );
          setCorporateId(theID);
          if (!!findContentCorporate) {
            setTitle(
              languages === "EN"
                ? !!findContentCorporate?.nom_corporate
                  ? findContentCorporate?.nom_corporate
                  : findContentCorporate?.french_nom
                : !!findContentCorporate?.french_nom
                ? findContentCorporate?.french_nom
                : findContentCorporate?.nom_corporate
            );
            setTag(findContentCorporate?.Contents[0]?.Style?.tags);
            setContent(findContentCorporate.Contents);
          } else {
            setContent([]);
          }
        }
      })
      .catch((error) =>
        console.log(
          error,
          "this error is catched when fetching corporates data in the client side in Corporates.js"
        )
      );
  };

  const handleClickCorporateItem = (id) => {
    // setCorporateId(id);
    navigate(`/corporates/${id}`);
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />

        <div className="corporate_container">
          <div className="text__content_corporate">
            <div className="corporate_list_section">
              <h1 className="grande__title_corporate">{Languages.Corporate}</h1>
              <ul className="corporate_list">
                {!!corporates &&
                  corporates.length &&
                  corporates.map((ele, index) => (
                    <li
                      className="corporate_items"
                      style={{
                        color: ele.id_corporate === corporateId && "#3e5080",
                      }}
                      key={ele.id_corporate}
                      onClick={() => handleClickCorporateItem(ele.id_corporate)}
                    >
                      {languages === "EN"
                        ? !!ele.nom_corporate
                          ? ele.nom_corporate
                          : ele.french_nom
                        : !!ele.french_nom
                        ? ele.french_nom
                        : ele.nom_corporate}
                    </li>
                  ))}
              </ul>
            </div>
            <AnimationWrapper corporateId={corporateId}>
              <div className="left_side_corporate">
                {tag === "image_title_text" &&
                  content.map((ele, index) => (
                    <ImageTitleText key={index} data={ele} lang={languages} />
                  ))}

                {tag === "image_title_logo_checklist" &&
                  content.map((ele, index) => (
                    <ImageTitleLogoChecklist
                      key={index}
                      data={ele}
                      lang={languages}
                    />
                  ))}

                {tag === "title_logo_date_description" && (
                  <TitleLogoDateDescreption
                    data={content}
                    title={title}
                    lang={languages}
                  />
                )}

                {tag === "image_title_subtitle_liste" && (
                  <ImageTitleSubtitleListe
                    data={content}
                    title={title}
                    lang={languages}
                  />
                )}

                {tag === "title_text" && (
                  <TitleText data={content} title={title} lang={languages} />
                )}
              </div>
            </AnimationWrapper>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
