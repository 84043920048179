import React, { useEffect } from "react";
import { useRoutes, BrowserRouter as Router } from "react-router-dom";
import {
  Home,
  Products,
  ProductDetails,
  Sectors,
  Filter,
  Technology,
  Gammes,
  Configurations,
  Corporates,
  InformatonCenter,
  News,
  Contact
} from "@screens";
import { Languages } from "@common";
import { useSelector } from "react-redux";

const AppLoggedRoutes = () => {
  const state = useSelector(({ account: { language } }) => language);

  useEffect(() => {
    if (!!state) {
      Languages.setLanguage(state);
    } else {
      Languages.setLanguage("EN");
    }
  }, [state]);

  let routes = useRoutes([
    { path: "/", element: <Home /> },
    {
      path: "/sectors/technologies/gammes/configurations/produits/:id/:name",
      element: <Products />,
    },
    { path: "/details", element: <ProductDetails /> },
    { path: "/sectors", element: <Sectors /> },
    { path: "/corporates", element: <Corporates /> },
    { path: "/contact", element: <Contact /> },
    { path: "/corporates/:id", element: <Corporates /> },
    { path: "/news/:id", element: <News /> },
    { path: "/information_center", element: <InformatonCenter /> },
    { path: "/information_center/:id", element: <InformatonCenter /> },
    { path: "/find_battrey/:id/:name", element: <Filter /> },
    { path: "/sectors/technologies/:id/:name", element: <Technology /> },
    { path: "/sectors/technologies/:id_bat/:name_bat/gammes/:id/:name", element: <Gammes /> },
    {
      path: "/sectors/technologies/gammes/configurations/:id/:name",
      element: <Configurations />,
    },
  ]);
  return routes;
};

export default ({}) => {
  return (
    <Router>
      <AppLoggedRoutes />
    </Router>
  );
};
