import { backend } from "../axios";

export const CORPORATES = {
  ALL: async () => {
    return await backend.get("/corporates");
  },
  FETCHONE: async (id) => {
    return await backend.get(`/corporates/${id}`);
  },
  FETCHBYCONTENT: async (id) => {
    return await backend.get(`/corporates/by_content/${id}`);
  }
};
