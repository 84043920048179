import React from "react";
import { PORT } from "@common";
import "./styles.css";

const hostname =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";

const URL =
  document.location.protocol +
  "//" +
  hostname +
  "/images/contents/";

export default ({ data, lang }) => {
  return (
    <div className="image_title_text">
      <div className="__image">
        <img src={URL + data.picture} />
      </div>
      <div className="__content">
        <h1 className="__title">
          {lang === "EN"
            ? !!data.title
              ? data.title
              : data.french_title
            : !!data.french_title
            ? data.french_title
            : data.title}
        </h1>
        <p className="__text">
          {lang === "EN"
            ? !!data.text
              ? data.text
              : data.french_text
            : !!data.french_text
            ? data.french_text
            : data.text}
        </p>
      </div>
    </div>
  );
};
