import React, { useState } from "react";
import { TabBar, Footer, AnimationWrapper } from "@components";
import { Fonts, useColors } from "@common";
import { useNavigate } from "react-router-dom";
import "./contact.css";
import ContactImage from "@images/contact.png";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import MailIcon from "@mui/icons-material/Mail";
import { Languages } from "@common";
import { useSelector } from "react-redux";

const Contact = () => {
  const languages = useSelector(({ account: { language } }) => language);
  console.log(languages, "************");
  const Colors = useColors();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div className="contact_page">
        <TabBar pressed={openMenu} useBack />
        {/* <AnimationWrapper> */}
        <div className="contact_section">
          <div
            className="contact_container"
            style={{ backgroundImage: `url(${ContactImage})` }}
          >
            <div className="wrapper_contact">
              <div className="leftSide_contact">
                <div className="cordonates_contacts">
                  <div className="left_inside_section">
                    <div className="contact_infos">
                      <div className="border_contact_small"></div>
                      <p
                        className="text_title_contact"
                        style={{
                          fontFamily: Fonts().primaryRegular,
                          color: "rgb(45, 45, 131)",
                          padding: "10px 0px",
                        }}
                      >
                       {Languages.FeelFreeTo}
                      </p>
                      <div className="border_contact_moyen"></div>
                      <h1
                        className="text_title_contact_h1"
                        style={{
                          fontFamily: Fonts().primaryBold,
                          color: "rgb(40, 41, 43)",
                        }}
                      >
                        {Languages.ContactUs}
                      </h1>
                      <div className="border_contact_colored"></div>
                      <div className="section___map">
                        <div className="left_content__">
                          <div className="border_contact_small"></div>
                          <p
                            className="title_cordonates"
                            style={{
                              fontFamily: Fonts().primarySemiBold,
                              color: "rgb(40, 41, 43)",
                              marginTop: "10px",
                            }}
                          >
                            {Languages.ContactDetails}
                          </p>
                          <div
                            className="location_infos"
                            style={{ marginTop: "20px" }}
                          >
                            <FmdGoodIcon
                              style={{
                                fontSize: 22,
                                color: "rgb(45, 45, 131)",
                                fontFamily: Fonts().primaryRegular,
                              }}
                            />
                            <p
                              className="location_adress"
                              style={{
                                fontSize: 12,
                                fontFamily: Fonts().primaryRegular,
                                marginTop: 10,
                              }}
                            >
                              Alma Bat- Bd Ahl loghlam –Sidi Moumen -20630
                              Casablanca, MAROC
                            </p>
                          </div>
                          <div className="location_infos">
                            <PhoneEnabledIcon
                              style={{
                                fontSize: 22,
                                color: "rgb(45, 45, 131)",
                              }}
                            />
                            <p
                              className="location_adress"
                              style={{
                                fontSize: 12,
                                fontFamily: Fonts().primaryRegular,
                                marginTop: 10,
                              }}
                            >
                              +212 522 76 73 56
                            </p>
                          </div>
                          <div className="location_infos">
                            <MailIcon
                              style={{
                                fontSize: 22,
                                color: "rgb(45, 45, 131)",
                              }}
                            />
                            <p
                              className="location_adress"
                              style={{
                                fontSize: 12,
                                fontFamily: Fonts().primaryRegular,
                                marginTop: 10,
                              }}
                            >
                              info@almabat.ma
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="map_section">
                      <iframe
                        src={`https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13293.042561435273!2d-7.4964923!3d33.5985412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7cb1114d1f42f%3A0xc392c646dd4f88f6!2sALMA%20BAT!5e0!3m2!1sen!2sma!4v1708595470972!5m2!1sen!2sma`}
                        width={window.innerWidth > 1300 ? 700 : 500}
                        height={window.innerWidth > 1300 ? 500 : 300}
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </AnimationWrapper> */}
      </div>
      <Footer />
    </>
  );
};

export default Contact;
