import "../index.css";
import React from "react";
import { Product } from "@components";

export default ({ pressed , products}) => {
  return (
    <>
      {!!products &&
        products.map((ele, index) => (
          <Product key={ele.id_product} data={ele} pressed={pressed} />
        ))}
    </>
  );
};
