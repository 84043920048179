import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export default ({
  label,
  pressed,
  style,
  useIcon,
  IconName,
  isFirst,
  iconStyle,
  remove
}) => {
  return (
    <Stack direction="row" spacing={2} style={{ width: remove ?  "auto" : "100%" }}>
      <Button style={style} onClick={pressed}>
        {useIcon && isFirst && (
          <IconName
            style={
              !!iconStyle ? iconStyle : { fontSize: "12px", marginLeft: "2px" }
            }
          />
        )}{" "}
        {label}{" "}
        {useIcon && !isFirst && (
          <IconName
            style={
              !!iconStyle ? iconStyle : { fontSize: "12px", marginLeft: "2px" }
            }
          />
        )}
      </Button>
    </Stack>
  );
};
