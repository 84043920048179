import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import Logo2 from "@images/logo.jpeg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  SOCIALS,
  NEWS,
  INFORMATIONCENTER,
  CORPORATES,
  CONFIGURATIONS,
} from "@services";
import { Languages } from "@common";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { innerWidth } = window;
export default ({}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [socials, setSocials] = useState([]);
  const [news, setNews] = useState([]);
  const [infos, setInfos] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [configs, setConfigs] = useState([]);
  const displayOnce = useRef(true);
  const lang = useSelector(({ account: { language } }) => language);

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchSocials();
      fetchNews();
      fetchInformationCenter();
      fetchCorporates();
      fetchConfiguration();
    }
  }, []);

  const fetchSocials = () => {
    setIsLoading(true);
    SOCIALS.ALL()
      .then((social) => {
        setIsLoading(false);
        setSocials(social.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const fetchConfiguration = () => {
    setIsLoading(true);
    CONFIGURATIONS.ALL()
      .then((config) => {
        setIsLoading(false);
        setConfigs(config.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleClickConfiguration = (id, type) => {
    navigate(
      `/sectors/technologies/gammes/configurations/produits/${id}/${type}`
    );
  };

  const fetchNews = () => {
    setIsLoading(true);
    NEWS.ALL()
      .then((social) => {
        setIsLoading(false);
        setNews(social.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const fetchCorporates = () => {
    setIsLoading(true);
    CORPORATES.ALL()
      .then((corporat) => {
        setIsLoading(false);
        setCorporates(corporat.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const fetchInformationCenter = () => {
    setIsLoading(true);
    INFORMATIONCENTER.ALL()
      .then((social) => {
        setIsLoading(false);
        setInfos(social.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleClickSocial = (link, id) => {
    if (link === 'null' || link === null || link === '' || link === undefined || !link) {
      navigate(`/news/${id}`);
      return;
    }
    if (!!link) {
      window.open(link);
    }
  };

  const handleClickInfos = (tags, id) => {
    navigate(`/information_center/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClickCorporates = (id) => {
    navigate(`/corporates/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="single__list">
          <h5>{Languages.Corporate}</h5>
          <div>
            <ul>
              {!!corporates &&
                corporates.map((ele, index) => (
                  <li
                    key={ele.id_corporate}
                    className="footer_li_items"
                    onClick={() => handleClickCorporates(ele.id_corporate)}
                  >
                    <a>
                      {" "}
                      {lang === "EN"
                        ? !!ele.nom_corporate
                          ? ele.nom_corporate
                          : ele.french_nom
                        : !!ele.french_nom
                        ? ele.french_nom
                        : ele.nom_corporate}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {/* <div className="single__list">
          <h5>{Languages.Products}</h5>
          <div>
            <ul>
              {!!configs &&
                configs.map((ele, index) => (
                  <li
                    key={ele.id_configuration}
                    className="footer_li_items"
                    onClick={() =>
                      handleClickConfiguration(
                        ele.id_configuration,
                        !!ele.type_configuration
                          ? ele.type_configuration
                          : ele.french_type
                      )
                    }
                  >
                    <a>
                      {" "}
                      {lang === "EN"
                        ? !!ele.type_configuration
                          ? ele.type_configuration
                          : ele.french_type
                        : !!ele.french_type
                        ? ele.french_type
                        : ele.type_configuration}{" "}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div> */}

        <div className="single__list">
          <h5>{Languages.InformationCenter}</h5>
          <div>
            <ul>
              {!!infos &&
                infos.map((ele, index) => (
                  <li
                    className="footer_li_items"
                    key={ele.id}
                    onClick={() => handleClickInfos(ele.tags, ele.id)}
                  >
                    <a>
                      {lang === "EN"
                        ? !!ele.tags
                          ? ele.tags
                          : ele.french_tags
                        : !!ele.french_tags
                        ? ele.french_tags
                        : ele.tags}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="single__list">
          <h5>{Languages.News}</h5>
          <div>
            <ul>
              {/* <li>
                <a>{Languages.News}</a>
              </li> */}
              {!!news &&
                news.map((ele, index) => (
                  <li
                    key={index}
                    className="footer_li_items"
                    onClick={() => handleClickSocial(ele.link, ele?.id)}
                  >
                    <a>
                      {" "}
                      {lang === "EN"
                        ? !!ele.title
                          ? ele.title
                          : ele.french_title
                        : !!ele.french_title
                        ? ele.french_title
                        : ele.title}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {/* <div className="single__list">
          <h5>Contact</h5>
          <div>
            <ul>
              <li>
                <a>--</a>
              </li>
              <li>
                <a>--</a>
              </li>
              <li>
                <a>--</a>
              </li>
            </ul>
          </div>
        </div> */}

        <div className="single__list find_item">
          <h5> {Languages.FindBattery} </h5>
          <div>
            <ul>
              <li
                className="footer_li_items"
                onClick={() => navigate("/sectors")}
              >
                <a>{Languages.ProductsList}</a>
              </li>
              {/* <li>
                <a>All Batteries</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="footer_container_responsive">
        <ul className="responsive__list">
          <li
            className="res__list__items"
            onClick={() => navigate(`/corporates`)}
          >
            <a onClick={() => navigate(`/corporates`)}>{Languages.Corporate}</a>
          </li>
          <li className="res__list__items">
            <a href="#" onClick={() => navigate("/sectors")}>
              Find Battery
            </a>
          </li>
          <li
            className="res__list__items"
            onClick={() => navigate(`/information_center`)}
          >
            <a onClick={() => navigate('/information_center')}>{Languages.InformationCenter}</a>
          </li>
          <li className="res__list__items" onClick={() => navigate("/news")}>
            <a >{Languages.News}</a>
          </li>
          <li className="res__list__items" onClick={() => navigate('/contact')}>
            <a >{Languages.Contact}</a>
          </li>
        </ul>
      </div>
      <div>
        <div className="logo_and_social">
          <img src={Logo2} alt={"ALMA"} className="footer__logo" />
          <div className="footer_social__media">
            {socials.map((ele, index) => (
              <div
                className="socials"
                key={ele.id}
                onClick={() => window.open(ele.link)}
              >
                <a>
                  {ele.tag === "Facebook_tag" && (
                    <FacebookIcon
                      style={{
                        fontSize: innerWidth > 600 ? "30px" : "18px",
                        color: "#fff",
                      }}
                    />
                  )}
                  {ele.tag === "LinkedIn_tag" && (
                    <LinkedInIcon
                      style={{
                        fontSize: innerWidth > 600 ? "30px" : "18px",
                        color: "#fff",
                      }}
                    />
                  )}
                  {ele.tag === "Instagram_tag" && (
                    <InstagramIcon
                      style={{
                        fontSize: innerWidth > 600 ? "30px" : "18px",
                        color: "#fff",
                      }}
                    />
                  )}
                  {ele.tag === "Youtube_tag" && (
                    <YouTubeIcon
                      style={{
                        fontSize: innerWidth > 600 ? "30px" : "18px",
                        color: "#fff",
                      }}
                    />
                  )}
                  {ele.tag === "Whatsapp_tag" && (
                    <WhatsAppIcon
                      style={{
                        fontSize: innerWidth > 600 ? "30px" : "18px",
                        color: "#fff",
                      }}
                    />
                  )}
                </a>
              </div>
            ))}
            {/* <div className="socials">
              <a>
                <FacebookIcon style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </div>
            <div className="socials">
              <a>
                <InstagramIcon style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </div>
            <div className="socials">
              <a>
                <TwitterIcon style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </div>
            <div className="socials">
              <a>
                <YouTubeIcon style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </div> */}
          </div>
        </div>
        <hr className="footer__border" />
        <div className="footer__privacy">
          <a> {Languages.AllRight}</a>
          <em> | </em>
          <a className="url"> {Languages.InformationSociety}</a>
          <em> | </em>
          <a className="url">{Languages.CookiesPolicy}</a>
        </div>
      </div>
    </footer>
  );
};
