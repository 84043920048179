import React, { useState, useEffect, useRef } from "react";
import { TabBar, Footer, AnimationWrapper } from "@components";
import "./index.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NEWS } from "@services";
import ImageTitleText from "../Corporates/components/ImageTitleText";
import TitleLogoDateDescreption from "../Corporates/components/TitleLogoDateDescreption";
import ImageTitleLogoChecklist from "../Corporates/components/ImageTitleLogoChecklist";
import ImageTitleSubtitleListe from "../Corporates/components/ImageTitleSubtitleListe";
import TitleText from "../Corporates/components/TitleText";
import { Languages } from "@common";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Nodata from "@images/Nodata.png";

export default ({}) => {
  const location = useLocation();
  const params = useParams();
  const languages = useSelector(({ account: { language } }) => language);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const displayOnce = useRef(true);
  const [tag, setTag] = useState(null);
  const [content, setContent] = useState([]);
  const [newId, setNewId] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchNews();
    }
    setNewId(params?.id);
  }, [languages, params?.id]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [newsData, newId, languages, location, params?.id]);

  useEffect(() => {
    setNewId(params?.id);
    const findContentNews = newsData.find(
      (ele) =>
        ele.id === newId &&
        (ele.link === "null" ||
          ele.link === null ||
          ele.link === "" ||
          ele.link === "undefined" ||
          ele.link === undefined)
    );
    if (!!findContentNews) {
      setTitle(
        languages === "EN"
          ? findContentNews?.title
          : findContentNews?.french_title
      );
      setTag(findContentNews?.Contents[0]?.Style?.tags);
      setContent(findContentNews.Contents);
    } else {
      setContent([]);
    }
  }, [newId, newsData, languages, params?.id]);

  const navigate = useNavigate();
  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  const fetchNews = () => {
    setContent([]);
    setIsLoading(true);
    setNewId(params?.id);
    NEWS.ALL()
      .then((result) => {
        setIsLoading(false);
        setNewsData(result.data.data);
        if (result.data.data.length > 0) {
          const findLinkNull = result.data.data.find(
            (ele) =>
              ele.link === "null" ||
              ele.link === null ||
              ele.link === "" ||
              ele.link === "undefined" ||
              ele.link === undefined
          );
          const theID = findLinkNull.id;
          const findContentNews = findLinkNull;
          setNewId(theID);
          if (!!findContentNews) {
            setTitle(
              languages === "EN"
                ? !!findContentNews?.title
                  ? findContentNews?.title
                  : findContentNews?.french_title
                : !!findContentNews?.french_title
                ? findContentNews?.french_title
                : findContentNews?.title
            );
            setTag(findContentNews?.Contents[0]?.Style?.tags);
            setContent(findContentNews.Contents);
          } else {
            setContent([]);
          }
        }
      })
      .catch((error) => {
        console.log(
          error,
          "this error is catched when fetching corporates data in the client side in Corporates.js"
        );
        setIsLoading(false);
      });
  };

  const handleClickInformationItem = (id) => {
    setIsLoading(true);
    navigate(`/news/${id}`);
    if (newId !== id) {
      setContent([]);
    }
    setTimeout(() => {
      setNewId(id);
      setIsLoading(false);
    }, 800);
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <div className="corporate_container">
          <div className="text__content_corporate">
            <div className="corporate_list_section">
              <h1 className="grande__title_corporate">{Languages.News}</h1>
              <ul className="corporate_list">
                {!!newsData &&
                  newsData.length &&
                  newsData.map((ele, index) => (
                    <>
                      {(ele.link === "null" ||
                        ele.link === null ||
                        ele.link === "" ||
                        ele.link === "undefined" ||
                        ele.link === undefined) && (
                        <li
                          className="corporate_items"
                          style={{
                            color: ele.id === newId && "#3e5080",
                          }}
                          key={ele.id}
                          onClick={() => handleClickInformationItem(ele.id)}
                        >
                          {languages === "EN"
                            ? !!ele.title
                              ? ele.title
                              : ele.french_title
                            : !!ele.french_title
                            ? ele.french_title
                            : ele.title}
                        </li>
                      )}
                    </>
                  ))}
              </ul>
            </div>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "300px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <AnimationWrapper corporateId={newId}>
                {!!content && content.length ? (
                  <div className="left_side_corporate">
                    {tag === "image_title_text" &&
                      content.map((ele, index) => (
                        <ImageTitleText key={index} data={ele} />
                      ))}

                    {tag === "image_title_logo_checklist" &&
                      content.map((ele, index) => (
                        <ImageTitleLogoChecklist key={index} data={ele} />
                      ))}

                    {tag === "title_logo_date_description" && (
                      <TitleLogoDateDescreption data={content} title={title} />
                    )}

                    {tag === "image_title_subtitle_liste" && (
                      <ImageTitleSubtitleListe data={content} title={title} />
                    )}

                    {tag === "title_text" && (
                      <TitleText data={content} title={title} />
                    )}
                  </div>
                ) : !isLoading && (
                  <div className="left_side_corporate">
                    <img src={Nodata} className="no_data_image" />
                  </div>
                )}
              </AnimationWrapper>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
