import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabBar, HeaderBoxChown, Footer, FilterBox } from "@components";
import "./index.css";

const Categories = [
  { id: 1, name: "Automobiles/Light Commercial Vehicles" },
  { id: 2, name: "Heavy Duty" },
  { id: 3, name: "Special Series" },
];

const ManuFacturer = [
  { id: 1, name: "ABARTH" },
  { id: 2, name: "AC" },
  { id: 3, name: "ACURA" },
  { id: 4, name: "ALFA REMIO" },
];
const ModeLine = [
  { id: 1, id_m: 4, name: "145 (930_)" },
  { id: 2, id_m: 1, name: "124 Spider (348_)" },
  { id: 3, id_m: 3, name: "RDX" },
  { id: 4, id_m: 3, name: "MDX (YD3)" },
  { id: 5, id_m: 2, name: "428 Convertible" },
  { id: 6, id_m: 4, name: "146 (930_)" },
];

const Version = [
  { id: 1, id_mode: 1, name: "3.5 (204 kW / 277 PS)" },
  { id: 2, id_mode: 2, name: "3.3 (204 kW / 277 PS)" },
  { id: 3, id_mode: 3, name: "3.4 (204 kW / 277 PS)" },
  { id: 4, id_mode: 4, name: "3.8 (204 kW / 277 PS)" },
  { id: 5, id_mode: 5, name: "3.6 (204 kW / 277 PS)" },
  { id: 6, id_mode: 6, name: "3.7 (204 kW / 277 PS)" },
];

const CatgeoryOfBatries = [
  { id: 1, id_v: 1, name: "Volthor" },
  { id: 2, id_v: 2, name: "Tab" },
  { id: 3, id_v: 3, name: "Vesna" },
  { id: 4, id_v: 4, name: "Topla" },
];

export default ({}) => {
  const params = useParams();
  const { id, name } = params;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [idCat, setIdCat] = useState(Number(id));
  const [disaper, setDisaper] = useState(false);

  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
      setDisaper((pr) => !pr);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  const pressed = (id) => {
    setIdCat(Number(id));
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <div
          onClick={() => setOpen(true)}
          className="shown__box"
          style={
            !open
              ? { opacity: 0, zIndex: -100, height: 0, top: "-300px" }
              : {
                  opacity: 1,
                  transition: "all .3s ease-in-out",
                  zIndex: 9999,
                  height: "auto",
                  top: "80px",
                }
          }
        >
          <HeaderBoxChown />
        </div>
      </div>
      <div className="find_battery_container">
        <div className="box__list">
          <ul className="filter__cat__list">
            {Categories.map((data) => (
              <li
                key={data.id}
                style={{ color: idCat === data.id && "red" }}
                onClick={() => pressed(data.id)}
              >
                {data.name}
              </li>
            ))}
          </ul>
        </div>

        <div className="filter__feilds__box">
          <FilterBox
            manuFacturer={ManuFacturer}
            modeLine={ModeLine}
            versions={Version}
            category={CatgeoryOfBatries}
            removeProduct
            disaper={disaper}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
