import LocalizedStrings from "react-localization";

export const Languages = new LocalizedStrings({
  EN: {
    FindBattery: "Products",
    Corporate: "Corporate",
    InformationCenter: "Information Center",
    News: "News",
    All: "SEE ALL",
    OurSectors: "Our batteries offer",
    OurSectorsDesc:"To find the right battery for your vehicle, first select a sector.",
    OurTechnologies: "Our technologies",
    OurTechnologiesDesc: "To find the right battery for your vehicle, first select a technology.",
    OurSelections: "Our Selections",
    OurSelectionsDesc: "To find the right battery for your vehicle, first select a gamme.",
    OurConfigurations: "Our configurations",
    OurConfigurationsDesc: "To find the right battery for your vehicle, first select a configuration.",
    Products: "Products",
    See: "View",
    TechnicalDataSheet: "Technical Data Sheet ENG",
    TechnicalDataSheetFR: "Technical Data Sheet FR",
    AllRight: "ALMA © 2022 All Rights Reserved",
    InformationSociety:"Information Society Services",
    CookiesPolicy: "Cookies and Privacy Policy",
    Details: "Details",
    ProductsList: "Products List",
    Contact: "Contact",
    FeelFreeTo: "Feel free to",
    ContactUs: "Contact us",
    ContactDetails: "Contact details",
    EmptyData: "Data is content-free"
  },
  FR: {
    FindBattery: "Produits",
    Corporate: "Entreprise",
    InformationCenter: "Centre d'information",
    News: "Actualités",
    All: "VOIR TOUS",
    OurSectors: "Nos offres",
    OurSectorsDesc:"Pour trouver la batterie adaptée à votre véhicule, sélectionnez d'abord un secteur.",
    OurTechnologies: "Nos technologies",
    OurTechnologiesDesc: "Pour trouver la batterie adaptée à votre véhicule, choisissez d'abord une technologie.",
    OurSelections: "Nos Gammes",
    OurSelectionsDesc: "Pour trouver la batterie adaptée à votre véhicule, sélectionnez d'abord une gamme.",
    OurConfigurations: "Nos configurations",
    OurConfigurationsDesc: "Pour trouver la batterie adaptée à votre véhicule, sélectionnez d'abord une configuration.",
    Products: "Produits",
    See: "Voir",
    TechnicalDataSheet: "Fiche techniqueENG",
    TechnicalDataSheetFR: "Fiche technique FR",
    AllRight: "ALMA © 2022 Tous droits réservés",
    InformationSociety:"Services de la société de l'information",
    CookiesPolicy: "Cookies et politique de confidentialité",
    Details: "Détails",
    ProductsList: "Liste des produits",
    Contact: "Contact",
    FeelFreeTo: "N'hésitez pas à",
    ContactUs: "Nous contacter",
    ContactDetails: "Coordonnées",
    EmptyData: "Les Données sont sans contenu"
    
  },
});