import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { LangIcon } from "@components";
import Logo2 from "@images/logo2.png";
import { useNavigate } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import { IconButton } from "@mui/material";
import ResponsiveMenu from "../ResponsiveMenu";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { SOCIALS } from "@services";
import { Languages } from "@common";

export default ({ pressed, useBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [socials, setSocials] = useState([]);
  const displayOnce = useRef(true);

  const clickEvent = (element) => {
    if (element === "corporate") {
      navigate("/corporates");
    } else if (element === "contact") {
      navigate("/contact");
    } else {
      navigate("/information_center");
    }
  };

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
    }
    fetchSocials();
  }, []);

  const fetchSocials = () => {
    setIsLoading(true);
    SOCIALS.ALL()
      .then((social) => {
        setSocials(social.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const categoryPressed = () => {
    navigate("/produits");
    setOpen(false);
  };

  return (
    <>
      <div
        className="tabBar"
        style={
          useBack
            ? {
                background: "#2d2d83",
              }
            : { background: "transparent" }
        }
      >
        <div className="logo" onClick={() => navigate("/")}>
          <img
            className="logo__image"
            src={Logo2}
            alt="Logo"
            style={{ width: "150px", cursor: "pointer" }}
          />
        </div>
        <ul className="listItems">
          <li
            className="item"
            onClick={() => clickEvent("corporate")}
            style={{
              color:
                document.location.href.split("/")[3] === "corporates" ||
                document.location.href.split("/")[3] === "news"
                  ? "#f7e905"
                  : "white",
            }}
          >
            <a>{Languages.Corporate}</a>
          </li>
          <li
            className="item"
            style={{
              color:
                document.location.href.split("/")[3] !== "information_center" &&
                document.location.href.split("/")[3] !== "corporates" &&
                document.location.href.split("/")[3] !== "contact" &&
                document.location.href.split("/")[3] !== "news" &&
                document.location.href.split("/")[3] !== ""
                  ? "#f7e905"
                  : "white",
            }}
          >
            <a onClick={() => pressed("cat")}>{Languages.FindBattery}</a>
          </li>
          {/* <li className="item">
            <a onClick={() => pressed("cat")}>Find Battery</a>
          </li> */}

          <li
            className="item"
            onClick={() => clickEvent("infos")}
            style={{
              color:
                document.location.href.split("/")[3] === "information_center"
                  ? "#f7e905"
                  : "white",
            }}
          >
            <a>{Languages.InformationCenter}</a>
          </li>
          <li
            className="item"
            onClick={() => clickEvent("contact")}
            style={{
              color:
                document.location.href.split("/")[3] === "contact"
                  ? "#f7e905"
                  : "white",
            }}
          >
            <a>{Languages.Contact}</a>
          </li>
        </ul>
        <div className="lang">
          <LangIcon />
          <div className="socail_media">
            {!!socials && socials.map((ele, index) => (
              <div key={index} onClick={() => window.open(ele?.link)}>
                {ele?.tag === "Facebook_tag" && (
                  <FacebookIcon
                    className="social_item"
                    style={{ fontSize: 20, marginTop: 4, marginRight: 8 }}
                  />
                )}
                {ele.tag === "LinkedIn_tag" && (
                  <LinkedInIcon style={{ fontSize: 20 , marginTop: 4, marginRight: 8  }} />
                )}
                {ele.tag === "Instagram_tag" && (
                  <InstagramIcon
                    className="social_item"
                    style={{ fontSize: 20,  marginTop: 4, marginRight: 8  }}
                  />
                )}
                {ele.tag === "Youtube_tag" && (
                  <YouTubeIcon
                    className="social_item"
                    style={{ fontSize: 20,  marginTop: 4, marginRight: 8  }}
                  />
                )}
                {ele.tag === "Whatsapp_tag" && (
                  <WhatsAppIcon
                    className="social_item"
                    style={{ fontSize: 20,  marginTop: 4, marginRight: 8  }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="menuBtn">
          <IconButton
            aria-label="delete"
            style={{ color: "#fff", marginLeft: 10 }}
            onClick={handleClickOpen}
          >
            <ReorderIcon style={{ fontSize: 32 }} />
          </IconButton>
        </div>
      </div>
      <ResponsiveMenu
        open={open}
        handleClose={handleClose}
        categoryPressed={categoryPressed}
      />
    </>
  );
};
