import React from "react";
import { PORT } from "@common";
import "./styles.css";
import DoneIcon from "@mui/icons-material/Done";


const hostname = document.location.hostname === 'localhost' ? "almabat.ma/app" : "almabat.ma/app";
const URL =
  document.location.protocol + "//" +  hostname + "/images/contents/";

export default ({ data, lang }) => {
  const checkList = JSON.parse(data?.check_list);
  return (
    <div className="image_title_text">
      <div className="__image">
        <img src={URL + data.picture} />
      </div>
      <div className="__content">
        <h1 className="__title">
          {lang === "EN"
            ? !!data.title
              ? data.title
              : data.french_title
            : !!data.french_title
            ? data.french_title
            : data.title}
        </h1>
        <div className="__checkList">
          {checkList.map((ele, index) => (
            <div className="___items">
              {!!ele.logo ? (
                <img src={URL + ele.logo} alt={ele.name} style={{width: "27px", marginRight: 10, height: "25px"}}/>
              ) : (
                <DoneIcon style={{ color: "#0660aa", fontSize: 22 }} />
              )}

              <h6 className="__checklist_items">
                {lang === "EN"
                  ? !!ele.name
                    ? ele.name
                    : ele.frenchName
                  : !!ele.frenchName
                  ? ele.frenchName
                  : ele.name}
              </h6>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
