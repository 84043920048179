import React, { useState, useEffect, useRef } from "react";
import {
  Slider2,
  TabBar,
  NewsBox,
  Button,
  Footer,
  AnimationWrapper,
} from "@components";
import "./index.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useNavigate } from "react-router-dom";
import { NEWS } from "@services";
import { Languages } from "@common";
import { useSelector } from "react-redux";


export default ({}) => {
  const languages = useSelector(({ account: { language } }) => language);
  const [open, setOpen] = useState(false);
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const displayOnce = useRef(true);

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchNews();
    }
  }, []);

  const fetchNews = () => {
    setIsLoading(true);
    NEWS.ALL()
      .then((news) => {
        setNews(news.data.data);
      })
      .catch((error) =>
        console.log(error, "**************$$$$$$*******************")
      );
  };

  const navigate = useNavigate();
  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <div style={{ 
          position: "relative",
          zIndex: 1,
          // display: "flex",
          // flexDirection: "column",
          // gap: "80px"
        }}>
          <TabBar pressed={openMenu} useBack/>  
        {/* <Slider pressed={() => !!open && setOpen(false)} lang={languages} /> */}
        <Slider2 pressed={() => !!open && setOpen(false)} lang={languages} />
        {/* {open && ( */}
        {/* <div
          onClick={() => setOpen(true)}
          className="shown__box"
          style={
            !open
              ? { opacity: 0, zIndex: -100 }
              : { opacity: 1, transition: "all .3s ease-in-out", zIndex: 9999 }
          }
        >
          <HeaderBoxChown />
        </div> */}
        {/* )} */}
      </div>
      {/* <div className="cards___boxes">
        {CardsInfos.map((ele, index) => (
          <CategoriesBox key={index} data={ele} />
        ))}
      </div> */}
      {/* TODO: Remove this comment section after */}
      {/* <div style={{ height: "400px", marginTop: "30px", marginBottom: 20 }}>
        <FlayerBox />
      </div> */}
      <div className="news">
        <div className="news__header">
          <h1>{Languages.News}</h1>
          <Button
            useIcon
            IconName={ArrowForwardIosIcon}
            label={Languages.All}
            pressed={() => console.log("see all pressed ....")}
            style={{ textTransform: "capitalize", fontWeight: "700"}}
            remove
          />
        </div>
        <AnimationWrapper>
          <div className="news__content">
            {!!news && news.map((ele, index) => (
              <NewsBox key={index} data={ele} lang={languages} />
            ))}
          </div>
        </AnimationWrapper>
      </div>
      {/* TODO: remove this below comment after */}
      {/* <div className="filter__box__home">
        <FilterBox
          manuFacturer={ManuFacturer}
          modeLine={ModeLine}
          versions={Version}
          category={CatgeoryOfBatries}
          products={Products}
          productPressed={() => navigate("/details")}
        />
      </div> */}
      <Footer />
    </>
  );
};
