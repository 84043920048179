import React from "react";
import Button from "../Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import { PORT, Languages } from "@common";
import { useNavigate } from "react-router-dom";

const port = document.location.hostname === "localhost" ? "8080" : PORT;
const hostname = document.location.hostname === 'localhost' ? 'almabat.ma/app' : "almabat.ma/app";

const URL =
  document.location.protocol + "//" + hostname + "/images/news";

export default ({ data, lang }) => {
  const naviagte = useNavigate();
  const handleOpenLink = (link,id) => {
    if (link === 'null' || link === null || link === '' || link === undefined || !link) {
      naviagte(`/news/${id}`);
      return;
    }
    if (!!link) {
      window.open(link);
    }
  };
  return (
    <div className="single__news" onClick={() => handleOpenLink(data.link, data.id)}>
      <div>
        <div
          style={{ backgroundImage: `url(${URL + "/" + data.picture})` }}
          className="image__news"
        ></div>
        <p className="news__title">
          {lang === "EN"
            ? !!data.title
              ? data.title
              : data.french_title
            : !!data.french_title
            ? data.french_title
            : data.title}
        </p>
        <p className="news__desc">
          {lang === "EN"
            ? !!data.description
              ? data.description
              : data.french_desc
            : !!data.french_desc
            ? data.french_desc
            : data.description}
        </p>
        <Button
          label={Languages.Details}
          pressed={() => handleOpenLink(data.link)}
          useIcon
          IconName={ArrowForwardIosIcon}
          style={{
            fontWeight: "bold",
            textTransform: "capitalize",
            marginLeft: "-8px",
          }}
        />
      </div>
    </div>
  );
};
