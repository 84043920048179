import React from "react";
import "./styles.css";

export default ({ title, data, lang }) => {
  return (
    <div className="image_title_text">
      <h1 className="main__titless">{title}</h1>
      {!!data &&
        data.map((ele, index) => (
          <div className="__content" key={index}>
            <h1 className="__title">
              {lang === "EN"
                ? !!ele.title
                  ? ele.title
                  : ele.french_title
                : !!ele.french_title
                ? ele.french_title
                : ele.title}
            </h1>
            <p className="__text">
              {lang === "EN"
                ? !!ele.text
                  ? ele.text
                  : ele.french_text
                : !!ele.french_text
                ? ele.french_text
                : ele.text}
            </p>
          </div>
        ))}
    </div>
  );
};
