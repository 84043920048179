import React, { useState, useEffect, useRef } from "react";
import { TabBar, Footer, AnimationWrapper } from "@components";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import { INFORMATIONCENTER } from "@services";
import ImageTitleText from "../Corporates/components/ImageTitleText";
import TitleLogoDateDescreption from "../Corporates/components/TitleLogoDateDescreption";
import ImageTitleLogoChecklist from "../Corporates/components/ImageTitleLogoChecklist";
import ImageTitleSubtitleListe from "../Corporates/components/ImageTitleSubtitleListe";
import TitleText from "../Corporates/components/TitleText";
import { Languages } from "@common";
import { useSelector } from "react-redux";
import Nodata from "@images/Nodata.png";

export default ({}) => {
  const params = useParams();
  const languages = useSelector(({ account: { language } }) => language);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [informationCenters, setInformationCenters] = useState([]);
  const displayOnce = useRef(true);
  const [tag, setTag] = useState(null);
  const [content, setContent] = useState([]);
  const [informationCenetrId, setInformationCenterId] = useState(
    !!params?.id ? params?.id : null
  );
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchInformationCenter();
    }
    setInformationCenterId(params?.id);
  }, [languages, params?.id]);

  useEffect(() => {
    const findContentCorporate = informationCenters.find(
      (ele) => ele.id === informationCenetrId
    );
    if (!!findContentCorporate) {
      setTitle(
        languages === "EN"
          ? !!findContentCorporate?.tags
            ? findContentCorporate?.tags
            : findContentCorporate?.french_tags
          : !!findContentCorporate?.french_tags
          ? findContentCorporate?.french_tags
          : findContentCorporate?.tags
      );
      setTag(findContentCorporate?.Contents[0]?.Style?.tags);
      setContent(findContentCorporate.Contents.sort((a, b) => a.id_content - b.id_content));
    } else {
      setContent([]);
    }
  }, [informationCenetrId, informationCenters, languages, params?.id]);

  const navigate = useNavigate();
  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  const fetchInformationCenter = () => {
    setIsLoading(true);
    INFORMATIONCENTER.ALL()
      .then((result) => {
        setIsLoading(false);
        setInformationCenters(result.data.data);
        if (result.data.data.length > 0) {
          let theID = null;
          if (!params?.id) {
            theID = result.data.data[0].id;
          } else {
            theID = params?.id;
          }
          const findContentCorporate = result.data.data.find(
            (ele) => ele.id === theID
          );
          setInformationCenterId(theID);
          if (!!findContentCorporate) {
            setTitle(
              languages === "EN"
                ? !!findContentCorporate?.tags
                  ? findContentCorporate?.tags
                  : findContentCorporate?.french_tags
                : !!findContentCorporate?.french_tags
                ? findContentCorporate?.french_tags
                : findContentCorporate?.tags
            );
            setTag(findContentCorporate?.Contents[0]?.Style?.tags);
            setContent(findContentCorporate.Contents);
          } else {
            setContent([]);
          }
        }
      })
      .catch((error) =>
        console.log(
          error,
          "this error is catched when fetching corporates data in the client side in Corporates.js"
        )
      );
  };

  const handleClickInformationItem = (id) => {
    // const findContentCorporate = informationCenters.find(
    //   (ele) => ele.id === id
    // );
    // setInformationCenterId(id);
    navigate(`/information_center/${id}`);
    // if (!!findContentCorporate) {
    //   setTitle(
    //     languages === "EN"
    //       ? !!findContentCorporate?.tags
    //         ? findContentCorporate?.tags
    //         : findContentCorporate?.french_tags
    //       : !!findContentCorporate?.french_tags
    //       ? findContentCorporate?.french_tags
    //       : findContentCorporate?.tags
    //   );
    //   setTag(findContentCorporate?.Contents[0]?.Style?.tags);
    //   setContent(findContentCorporate.Contents);
    // } else {
    //   setContent([]);
    // }
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <div className="corporate_container">
          <div className="text__content_corporate">
            <div className="corporate_list_section">
              <h1 className="grande__title_corporate">
                {Languages.InformationCenter}
              </h1>
              <ul className="corporate_list">
                {!!informationCenters &&
                  informationCenters.length &&
                  informationCenters.map((ele, index) => (
                    <li
                      className="corporate_items"
                      style={{
                        color: ele.id === informationCenetrId && "#3e5080",
                      }}
                      key={ele.id}
                      onClick={() => handleClickInformationItem(ele.id)}
                    >
                      {languages === "EN"
                        ? !!ele.tags
                          ? ele.tags
                          : ele.french_tags
                        : !!ele.french_tags
                        ? ele.french_tags
                        : ele.tags}
                    </li>
                  ))}
              </ul>
            </div>
            <AnimationWrapper corporateId={informationCenetrId}>
              {!!content && content.length > 0 ? (
                <div className="left_side_corporate">
                  {tag === "image_title_text" &&
                    content.map((ele, index) => (
                      <ImageTitleText key={index} data={ele} lang={languages} />
                    ))}

                  {tag === "image_title_logo_checklist" &&
                    content.map((ele, index) => (
                      <ImageTitleLogoChecklist
                        key={index}
                        data={ele}
                        lang={languages}
                      />
                    ))}

                  {tag === "title_logo_date_description" && (
                    <TitleLogoDateDescreption
                      data={content}
                      title={title}
                      lang={languages}
                    />
                  )}

                  {tag === "image_title_subtitle_liste" && (
                    <ImageTitleSubtitleListe
                      data={content}
                      title={title}
                      lang={languages}
                    />
                  )}

                  {tag === "title_text" && (
                    <TitleText data={content} title={title} lang={languages} />
                  )}
                </div>
              ) : (
                <div className="left_side_corporate">
                  <img src={Nodata} className="no_data_image" />
                </div>
              )}
            </AnimationWrapper>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
