import "./index.css";
import React from "react";
import BatteryFinder from "@images/battery_finder.png";
import Select from "../Select";
import Product from "../Product";

export default ({
  manuFacturer,
  modeLine,
  versions,
  category,
  products,
  productPressed,
  removeProduct,disaper
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${BatteryFinder})` }}
      className="battery_finder_image"
    >
      <div className="box__filter__search">
        <div className="search__header">
          <h3>
            <span>B</span>ATTERY FINDER
          </h3>
          <hr className="hr__search" />
        </div>
        <div className="search__inputs">
          <Select Label={disaper ? "" : "Manu Facturer"} data={manuFacturer} />
          <Select Label={disaper ? "" :"Mode lines"} data={modeLine} />
          <Select Label={disaper ? "" :"Choose version"} data={versions} />
          <Select Label={disaper ? "" :"Choose battery type"} data={category} />
        </div>
        {!removeProduct && (
          <div className="products__space">
            {!!products &&
              products.map((ele, index) => (
                <Product
                  key={ele.id}
                  data={ele}
                  pressed={productPressed}
                  isFilter
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
