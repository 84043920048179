import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import { TabBar, HeaderBoxChown, Footer, AnimationWrapper } from "@components";
import { useNavigate } from "react-router-dom";
import CategoryItem from "./components/Category";
import { SECTORS } from "@services";
import { Languages } from "@common";
import { useSelector } from "react-redux";

export default ({}) => {
  const navigate = useNavigate();
  const languages = useSelector(({ account: { language } }) => language);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [setcors, setSectors] = useState([]);
  const displayOnce = useRef(true);

  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchSectors();
    }
  }, [languages]);

  const fetchSectors = () => {
    setIsLoading(true);
    SECTORS.ALL()
      .then((result) => {
        setIsLoading(false);
        if (result.data.data) {
          setSectors(result.data.data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(
            error,
            "****** This error is catched in the fetch sectors list function ***********"
          );
        }
      });
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <AnimationWrapper>
          <div
            onClick={() => setOpen(true)}
            className="shown__box"
            style={
              !open
                ? { opacity: 0, zIndex: -100, height: 0, top: "-300px" }
                : {
                    opacity: 1,
                    transition: "all .3s ease-in-out",
                    zIndex: 9999,
                    height: "auto",
                    top: "80px",
                  }
            }
          >
            <HeaderBoxChown />
          </div>
        </AnimationWrapper>
      </div>
      <AnimationWrapper>
        <div className="category_container">
          <div className="text__content">
            <h1 className="grande__title">{Languages.OurSectors}</h1>
            {/* <p className="cat__desc">{Languages.OurSectorsDesc}</p> */}
          </div>
          <div className="items__box">
            {setcors.length > 0 &&
              setcors.map((ele) => (
                <CategoryItem data={ele} key={ele.id_sector} lang={languages} />
              ))}
          </div>
        </div>
      </AnimationWrapper>
      <Footer />
    </>
  );
};
