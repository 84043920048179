import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import {
  TabBar,
  HeaderBoxChown,
  Footer,
  AnimationWrapper,
  Explain,
} from "@components";
import { useNavigate, useParams } from "react-router-dom";
import CategoryItem from "./components/Gammes";
import { GAMMES, EXPLAIN } from "@services";
import { Languages } from "@common";
import { useSelector } from "react-redux";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";

export default ({}) => {
  const { id, name, id_bat, name_bat } = useParams();
  const languages = useSelector(({ account: { language } }) => language);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gammes, setGammes] = useState([]);
  const [explain, setExplain] = useState([]);
  const displayOnce = useRef(true);
  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (displayOnce.current) {
      displayOnce.current = false;
      fetchGammes();
      fetchExplain();
    }
  }, [languages]);

  const fetchExplain = () => {
    setIsLoading(true);
    EXPLAIN.ALL("gammes")
      .then((result) => {
        setIsLoading(false);
        if (result.data.data) {
          setExplain(result.data.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err) {
          console.log(
            err,
            "****** This error is catched in the fetch explain list function ***********"
          );
        }
      });
  };

  const fetchGammes = () => {
    setIsLoading(true);
    GAMMES.FINDBYTECHNOLOGY(id)
      .then((result) => {
        setIsLoading(false);
        if (result.data.data) {
          setGammes(result.data.data);
        }
      })
      .catch((error) => {
        if (error) {
          console.log(
            error,
            "****** This error is catched in the fetch gammes list function ***********"
          );
        }
      });
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <AnimationWrapper>
          <div
            onClick={() => setOpen(true)}
            className="shown__box"
            style={
              !open
                ? { opacity: 0, zIndex: -100, height: 0, top: "-300px" }
                : {
                    opacity: 1,
                    transition: "all .3s ease-in-out",
                    zIndex: 9999,
                    height: "auto",
                    top: "80px",
                  }
            }
          >
            <HeaderBoxChown />
          </div>
        </AnimationWrapper>
      </div>
      <AnimationWrapper>
        <div className="category_container">
          <Explain data={explain} />
          <div className="text__content">
            {(Number(id_bat) !== 10 || name_bat !== "Industrial Batteries") && (
              <h1 className="grande__title">{Languages.OurSelections}</h1>
            )}
            {gammes.length === 0 && (
              <p className="cat__desc">
                {Languages.EmptyData} <HourglassDisabledIcon style = {{position: "relative", top: "4px"}}/>
              </p>
            )}
            {gammes.length > 0 && (
              <p className="cat__desc">
                {languages === "EN"
                  ? !!gammes[0].Technology.desc_eng &&
                    gammes[0].Technology.desc_eng
                  : !!gammes[0].Technology.desc_fr &&
                    gammes[0].Technology.desc_fr}
              </p>
            )}
          </div>
          <div className="items__box">
            {gammes.length > 0 &&
              gammes.map((ele) => (
                <CategoryItem
                  data={ele}
                  key={ele.id_selection}
                  lang={languages}
                  idBat={id_bat}
                  nameBat={name_bat}
                />
              ))}
          </div>
        </div>
      </AnimationWrapper>
      <Footer />
    </>
  );
};
