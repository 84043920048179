import React, { useState, useEffect } from "react";
import { PORT } from "@common";
import { DateFormat } from "@components";

const port = document.location.hostname === "localhost" ? "8080" : PORT;
const hostname =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";

const URL = document.location.protocol + "//" + hostname + "/images/contents/";

export default ({ title, data, lang }) => {
  return (
    <div className="image_title_subtitle_liste_container">
      <h1 className="main__titless">{title}</h1>
      {data.map((ele, index) => (
        <div className="image_title_subtitle_liste" key={index}>
          <img className="__logo__list" src={URL + ele.picture} />
          <div className="left__side__liste">
            <div className="__titles">
              <h1 className="__title__list">
                {lang === "EN"
                  ? !!ele.title
                    ? ele.title
                    : ele.french_title
                  : !!ele.french_title
                  ? ele.french_title
                  : ele.title}
              </h1>
              <h4 className="__subTitle__list">
                {lang === "EN"
                  ? !!ele.sub_title
                    ? ele.sub_title
                    : ele.french_sub_title
                  : !!ele.french_sub_title
                  ? ele.french_sub_title
                  : ele.sub_title}
              </h4>
            </div>
            <div className="__liste__items">
              {JSON.parse(ele.label_value_list).map((ele, index) => (
                <div key={index} className="__items__">
                  <strong className="__items__label">
                    {lang === "EN" ? ele.label : ele.frenchLabel} :
                  </strong>
                  <span className="__items__value">
                    {lang === "EN" ? ele.value : ele.frenchValue}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
