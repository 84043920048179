import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import { TabBar, HeaderBoxChown, Footer, AnimationWrapper } from "@components";
import RightBox from "./components/RightBox";
import LeftBox from "./components/LeftBox";
import { useNavigate, useParams } from "react-router-dom";
import { Languages } from "@common";
import {
  PRODUCTS,
  SECTORS,
  TECHNOLOGIES,
  GAMMES,
  CONFIGURATIONS,
} from "@services";
import { useSelector } from "react-redux";

export default ({}) => {
  const languages = useSelector(({ account: { language } }) => language);
  const { id, name } = useParams();
  const displayOnce = useRef(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [gammes, setGammes] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [expanded, setExpanded] = React.useState("");
  const [expanded2, setExpanded2] = React.useState("");
  const [expanded3, setExpanded3] = React.useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    if (displayOnce.current) {
      fetchProducts(id);
      fetchSectors();
      displayOnce.current = false;
    }
  }, [languages]);

  const fetchSectors = () => {
    setIsLoading(true);
    SECTORS.ALL()
      .then((result) => {
        setIsLoading(false);
        setSectors(result.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error, "Error catching while fetching sectors");
      });
  };

  const openMenu = (param) => {
    if (param === "prod") {
      setOpen((prv) => !prv);
    } else if (param === "cat") {
      navigate("/sectors");
    } else {
      navigate("/");
    }
  };

  const download = (fileNOM) => {
    PRODUCTS.DOWNOALD(fileNOM)
      .then((response) => {
        // return;
        // Create a download link and trigger download
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        // Create a download link and trigger a download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileNOM; // Set the desired file name
        downloadLink.click();
      })
      .catch((error) => {
        console.log(error, "****** error ******");
        // toast("error", "Error while downloading file " + fileNOM);
      });
  };

  const fetchProducts = (idPro) => {
    setIsLoading(true);
    PRODUCTS.FINDPRODUCTSBYCONFIG(idPro)
      .then((result) => {
        setIsLoading(false);
        setProducts(result.data.data);
      })
      .catch((error) =>
        console.error(
          error,
          "***** This error occurred while fetching products from the database in the products.js screen *****"
        )
      );
  };

  const handleChange = (panel, id) => (event, newExpanded) => {
    setExpanded(newExpanded ? `${panel}` : false);
    fetchTechnologies(id);
  };
  const handleChange2 = (panel, id) => (event, newExpanded) => {
    setExpanded2(newExpanded ? `${panel}` : false);
    fetchGammes(id);
  };
  const handleChange3 = (panel, id) => (event, newExpanded) => {
    setExpanded3(newExpanded ? `${panel}` : false);
    fetchConfiguration(id);
  };

  const fetchConfiguration = (id) => {
    setIsLoading(true);
    CONFIGURATIONS.BYGAMMES(id)
      .then((result) => {
        setIsLoading(false);
        setConfigurations(result.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          error,
          "Error catching when fetching configuration in the products screen"
        );
      });
  };

  const fetchGammes = (id) => {
    setIsLoading(true);
    GAMMES.FINDBYTECHNOLOGY(id)
      .then((result) => {
        setIsLoading(false);
        setGammes(result.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          error,
          "Error catching when fetching gammes in the products screen"
        );
      });
  };

  const fetchTechnologies = (id) => {
    setIsLoading(true);
    TECHNOLOGIES.BYSECTORS(id)
      .then((result) => {
        setIsLoading(false);
        setTechnologies(result.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(
          error,
          "Error catching when fetching technologies in the products screen"
        );
      });
  };

  const handleClickConf = (idC, name) => {
    navigate(
      `/sectors/technologies/gammes/configurations/produits/${idC}/${name.replace(
        "/",
        "_"
      )}`
    );
    fetchProducts(idC);
    fetchSectors(idC);
  };

  return (
    <>
      <div style={{ position: "relative", zIndex: 1 }}>
        <TabBar pressed={openMenu} useBack />
        <AnimationWrapper>
          <div
            onClick={() => setOpen(true)}
            className="shown__box"
            style={
              !open
                ? { opacity: 0, zIndex: -100, height: 0, top: "-300px" }
                : {
                    opacity: 1,
                    transition: "all .3s ease-in-out",
                    zIndex: 9999,
                    height: "auto",
                    top: "80px",
                  }
            }
          >
            <HeaderBoxChown />
          </div>
        </AnimationWrapper>
      </div>
      <AnimationWrapper>
        <div className="products__box" onClick={() => !!open && setOpen(false)}>
          <div className="product__filter">
            <div className="filter__cat__title">
              <h1 className="filter_cat_h1">{Languages.Products}</h1>
            </div>
            {sectors.length > 0 &&
              sectors.map((ele, index) => (
                <RightBox
                  lang={languages}
                  data={ele}
                  key={ele.id_sector}
                  expanded={expanded}
                  handleChange={handleChange}
                  technologies={technologies}
                  expanded2={expanded2}
                  handleChange2={handleChange2}
                  gammes={gammes}
                  expanded3={expanded3}
                  handleChange3={handleChange3}
                  configurations={configurations}
                  handleClickConf={handleClickConf}
                />
              ))}
          </div>
          <div className="product__items">
            <LeftBox
              products={products}
              // pressed={() => navigate("/details")}
              pressed={download}
            />
          </div>
        </div>
      </AnimationWrapper>
      <Footer />
    </>
  );
};
