import { backend } from "../axios";

export const CONFIGURATIONS = {
  ALL: async () => {
    return await backend.get("/configurations");
  },
  BYGAMMES: async (id) => {
    return await backend.get(`/configurations/by_gammes/${id}`);
  },
};
