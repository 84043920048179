import React, { useState, useEffect } from "react";
import Router from "./Router";
import { useColors, useIsDarkMode } from "@common";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

function App() {
  const Colors = useColors();
  const isDark = false; //useIsDarkMode();


  
  // useEffect(() => {
  //   // Initialize Google Analytics with your Measurement ID
  //   ReactGA.initialize("G-W68BMEW6BY");

  //   // Send a page view for the initial load
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  //   // Optionally, track page changes with React Router
  //   const handleRouteChange = () => {
  //     ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  //   };

  //   // Add event listener for route changes
  //   window.addEventListener("popstate", handleRouteChange);

  //   // Clean up on unmount
  //   return () => window.removeEventListener("popstate", handleRouteChange);
  // }, []);

  useEffect(() => {
    (function (c, s, q, u, a, r, e) {
      c.hj = c.hj || function () { (c.hj.q = c.hj.q || []).push(arguments) };
      c._hjSettings = { hjid: 5221702 };
      r = s.getElementsByTagName('head')[0];
      e = s.createElement('script');
      e.async = 1;
      e.src = q + c._hjSettings.hjid + u;
      r.appendChild(e);
    })(window, document, 'https://static.hj.contentsquare.net/c/csq-', '.js');
  }, []);
  

  useEffect(() => {
    // Initialize GTM with your Container ID
    TagManager.initialize({ gtmId: "GTM-NFSF27V8" });
  }, []);



  return (
    <div
      className="App"
      style={{ height: "100%" }}
      // backgroundColor: Colors.backgroundDark,
    >
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Router />
        </Provider>
      </PersistGate>
    </div>
  );
}

export default App;
