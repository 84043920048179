import React, { useState, useEffect } from "react";
import { PORT } from "@common";
import { DateFormat } from "@components";
const port = document.location.hostname === "localhost" ? "8080" : PORT;
const hostname =
  document.location.hostname === "localhost"
    ? "almabat.ma/app"
    : "almabat.ma/app";

const URL =
  document.location.protocol +
  "//" +
  hostname +
  "/images/contents/";

export default ({ title, data, lang }) => {
  return (
    <div className="title_logo_date_description_container">
      <h1 className="main__titless">{title}</h1>
      {data.map((ele, index) => (
        <div className="title_logo_date_description" key={index}>
          <img
            className="__logo"
            src={URL + ele.logo}
            style={{ width: "40px" }}
          />
          <DateFormat
            isNotDate
            date={ele?.date}
            useDate
            style={{
              color: "#647299",
              fontWeight: "bold",
              fontSize: "19px",
              letterSpacing: "2px",
            }}
          />
          {/* <p className="__descreptions">
            {lang === "EN"
              ? !!ele.description
                ? ele.description
                : ele.french_desc
              : !!ele.french_desc
              ? ele.french_desc
              : ele.description}
          </p> */}
          <div
            style={{ marginLeft: 30 }}
            dangerouslySetInnerHTML={{
              __html:
                lang === "EN"
                  ? !!ele.description
                    ? ele.description
                    : ele.french_desc
                  : !!ele.french_desc
                  ? ele.french_desc
                  : ele.description,
            }}
          />
        </div>
      ))}
    </div>
  );
};
