import { LOGIN, LOGOUT, SET_LANGUAGE } from "./types";

const initialState = {
  isLogged: false,
  user: null,
  language: null,
  languageChanged: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: { ...action.payload },
        isLogged: true,
      };
    case LOGOUT: {
      return { ...state, isLogged: false, user: null };
    }
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        languageChanged: true,
      };
    default:
      return state;
  }
};
